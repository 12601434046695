import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import { SPAREPART_PO_STATUS } from '../../utils/dropdownOptions';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchSparePartPurchaseOrders,
    getSparePartPurchaseOrders,
} from '../../app/reducers/PurchaseOrder/sparePartPurchaseOrderSlice';
import moment from 'moment';
import ViewSparePartModel from './ViewSparePartModel';
import SparePartPOStatusModal from './SparePartPOStatusModal';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import { authAxiosInstance } from '../../utils/axiosConfig';
import QueryString from 'qs';
import { exportCsv } from '../../utils/Utils';

const ViewSparePartPO = () => {
    const { poStatus } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [sparePartPOStatusData, setSparePartPOStatusData] = useState({});
    const [page, setPage] = useState(1);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [sparePartPOData, setSparePartPOData] = useState();
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const { sparePartPurchaseOrder, loading } = useSelector(
        getSparePartPurchaseOrders
    );
    console.log('setSparePartPOData', sparePartPOData);
    const formik = useFormik({
        initialValues: {
            from: moment().subtract(1, 'months').startOf('day').valueOf(),
            to: moment().endOf('day').valueOf(),
        },
        onSubmit: async (values) => {
                    const string = QueryString.stringify({
                        populate: true,
                        page: 1,
                        limit: 5000,
                        createdAt: {
                            $gte: formik.values.from,
                            $lte: formik.values.to,
                        },
                    });
                    const response = await authAxiosInstance.get(
                        `/sparePartPurchaseOrder?${string}`
                    );
                    if (response?.data?.data?.docs?.length > 0) {
                        let updatedData = response?.data?.data?.docs?.map((d) => {
                            return {
                                // ...d,
                                customer: d.customer.name,
                                company: d?.customer?.companyData?.name,
                                product: d.product.name,
                                createdAt: moment(d.createdAt).format(
                                    'DD/MM/YYY HH:mm'
                                ),
                                updatedAt: moment(d.updatedAt).format(
                                    'DD/MM/YYY HH:mm'
                                ),
                                orderNumber: d?.sparePartPurchaseOrderNumber,
                                status: d?.status,
                            };
                        });
                        exportCsv(updatedData);
                    } else {
                        alert('No Data Found');
                    }
                },
    });

    const columns = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + sparePartPurchaseOrder?.pagingCounter;
            },
        },
        {
            Header: 'Date',
            Cell: ({ row }) => {
                return (
                    <>
                        {row?.original?.createdAt ? (
                            <p className="flex items-center">
                                {moment(row?.original?.createdAt).format(
                                    'DD/MM/YYYY HH:mm'
                                )}
                            </p>
                        ) : (
                            ''
                        )}
                    </>
                );
            },
        },
        {
            Header : 'Order Number',
            accessor :'sparePartPurchaseOrderNumber',
            Cell:({row})=>{
                return(
                    <div className='text-center'>{row?.original?.sparePartPurchaseOrderNumber}</div>
                )
            }
        },
        {
            Header: 'company',
            accessor: 'company.name',
        },
        {
            Header: 'product',
            accessor: 'product.name',
        },
        {
            Header: 'customer',
            accessor: 'customer.name',
        },
        {
            Header : 'Message',
            accessor : 'notes',
        },
        {
            Header: 'Spare Part',
            Cell: ({ row }) => {
                return (
                    <div className="flex items-center gap-2 cursor-pointer">
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsViewModalOpen(true);
                                setSparePartPOData(row?.original?.spareParts);
                            }}
                            type="button"
                        >
                            view
                        </PrimaryButton>
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: (prop) => {
                const { data, row } = prop;
                return (
                    <div className="flex items-center gap-2 cursor-pointer">
                        <PrimaryButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsStatusModalOpen(true);
                                setSparePartPOStatusData(row?.original);
                            }}
                            type="button"
                        >
                            Update Status
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];
    useEffect(() => {
        dispatch(
            fetchSparePartPurchaseOrders({
                status: poStatus,
                populate: true,
                page,
            })
        );
    }, [page, poStatus]);
    const columnsMemo = useMemo(() => columns, [columns]);
    const dataMemo = useMemo(
        () => (sparePartPurchaseOrder.docs ? sparePartPurchaseOrder.docs : []),
        [sparePartPurchaseOrder.docs]
    );
    console.log('dataMemo', dataMemo);
    //global search
    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(
                fetchSparePartPurchaseOrders({
                    populate: true,
                    search,
                    page,
                })
            );
        }, 300),
        []
    );
    return (
        <PageWithCard heading="View Spare Part Purchase Orders">
            <SparePartPOStatusModal
                isStatusModalOpen={isStatusModalOpen}
                setIsStatusModalOpen={setIsStatusModalOpen}
                sparePartPOStatusData={sparePartPOStatusData}
                page={page}
                currentStatus={poStatus}
            />
            <ViewSparePartModel
                sparePartPOData={sparePartPOData}
                setOpenModal={setIsViewModalOpen}
                openModal={isViewModalOpen}
            />
            <ShowDropdown
                heading={'Reporting'}
                allowedRoles={['admin', 'sup_admin', 'local_admin']}
            >
                <form
                    className="flex flex-col gap-4 mb-4"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="flex gap-5">
                        <FormikInputDateGroup
                            formik={formik}
                            name={'from'}
                            label="From"
                            required
                        />
                        <FormikInputDateGroup
                            formik={formik}
                            name="to"
                            label="To"
                            required
                        />
                    </div>
                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Submit
                        </PrimaryButton>
                    </div>
                </form>
            </ShowDropdown>
            <div className="flex gap-4 my-4">
                {SPAREPART_PO_STATUS?.map((ele) => {
                    if (ele.value == poStatus) {
                        return <SecondaryButton>{ele.label}</SecondaryButton>;
                    } else {
                        return (
                            <PrimaryButton
                                onClick={() =>
                                    navigate(
                                        `/purchaseOrder/view-SparePartPO/${ele.value}`
                                    )
                                }
                            >
                                {ele.label}
                            </PrimaryButton>
                        );
                    }
                })}
            </div>
            <TableWithHeadingAndGlobalSearch
                heading={`View SparePart Purchase Order`}
                tableClass="max-h-[350px] overflow-x-auto"
                loading={loading}
                columns={columnsMemo}
                data={dataMemo}
                searchFunction={(value) => {
                    debouncedSearch(value);
                }}
            />
            <PaginationClassic
                paginationDetails={{
                    ...sparePartPurchaseOrder,
                    docs: sparePartPurchaseOrder.docs,
                }}
                setPage={setPage}
            />
        </PageWithCard>
    );
};

export default ViewSparePartPO;
