import { useFormik } from 'formik';
import React, { useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import { PURCHASE_ORDER_STATUS } from '../../utils/dropdownOptions';
import { useDispatch, useSelector } from 'react-redux';
import { createPurchaseOrder } from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import * as Yup from 'yup';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { generateOptions } from '../../utils/Utils';
import { toast } from 'react-toastify';

const CreatePurchaseOrder = () => {
    const { user } = useSelector(getAuth);
    const [customerOptions, setCustomerOptions] = useState({});
    const [inkOptions, setInkOptions] = useState([]);
    const [MOQ, setMOQ] = useState({
        cyan: 0,
        magenta: 0,
        yellow: 0,
        black: 0,
    });
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            customer: '',
            product: '',
            notes: '',
            status: '',
            cyan: 0,
            magenta: 0,
            yellow: 0,
            black: 0,
            cleaningSolution: '',
        },
        validationSchema: Yup.object({
            customer: Yup.string().required(),
            product: Yup.string().required(),
            notes: Yup.string(),
            status: Yup.string(),
            cyan: Yup.number(),
            magenta: Yup.number(),
            yellow: Yup.number(),
            black: Yup.number(),
            cleaningSolution: Yup.string(),
        }),
        onSubmit: async (values) => {
            console.log('values', values);
            if (values?.cyan > 0 && values?.cyan < MOQ.cyan) {
                toast.error(`MOQ for Cyan not less then ${MOQ.cyan}`);
                return;
            }
            if (values?.magenta > 0 && values?.magenta < MOQ.magenta) {
                toast.error(`MOQ for Magenta not less then ${MOQ.magenta}`);
                return;
            }
            if (values?.yellow > 0 && values?.yellow < MOQ.yellow) {
                toast.error(`MOQ for Yellow not less then ${MOQ.yellow}`);
                return;
            }
            if (values?.black > 0 && values?.black < MOQ.black) {
                toast.error(`MOQ for Black not less then ${MOQ.black}`);
                return;
            }
            await dispatch(createPurchaseOrder(values));
            formik.resetForm();
        },
    });
    return (
        <PageWithCard heading="Create Purchase Order">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-2"
            >
                <FormikAsyncSelect
                    label="Select Company"
                    name={'company'}
                    formik={formik}
                    getOptions={async (value) => {
                        try {
                            const payload = {
                                search: value,
                                approvedStatus: 'approved',
                            };
                            if (user?.role === 'local_admin') {
                                payload['dispatchLocation'] = user?.location;
                            }
                            if (user?.role === 'executive') {
                                payload['executive'] = { $in: [user?._id] };
                            }
                            const string = QueryString.stringify(payload);
                            const response = await authAxiosInstance.get(
                                `/company?${string}`
                            );
                            const options = response?.data?.data?.docs?.map(
                                (ele) => ({
                                    label: ele.name,
                                    value: ele._id,
                                })
                            );

                            return options;
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    onChange={async (selectedOption) => {
                        const searchPayload = {
                            company: selectedOption.value,
                        };
                        const string = QueryString.stringify(searchPayload);
                        const customerResp = await authAxiosInstance.get(
                            `/customer?${string}`
                        );
                        const customerDocs = customerResp?.data?.data?.docs
                            ? customerResp?.data?.data?.docs
                            : [];
                        setCustomerOptions(customerDocs);
                        formik.setFieldValue('company', selectedOption.value);
                        formik.setFieldValue(
                            'customer',
                            customerDocs?.[0]?._id
                        );
                        if (customerDocs?.[0]?._id) {
                            const payload = {
                                _id: customerDocs?.[0]?._id,
                                populate: true,
                            };
                            const stringData = QueryString.stringify(payload);
                            const resp = await authAxiosInstance.get(
                                `/customer?${stringData}`
                            );
                            console.log('resp', resp?.data?.data?.docs[0]);
                            const inks = resp?.data?.data?.docs[0]?.ink;
                            setInkOptions(inks);
                        }
                    }}
                />
                {formik?.values?.company && (
                    <FormikSelectGroup
                        formik={formik}
                        name={'customer'}
                        label="Customer"
                        options={generateOptions({
                            array: customerOptions,
                            labelField: 'name',
                            valueField: '_id',
                        })}
                        // onChange={async (selectedOption) => {

                        //     formik.setFieldValue(
                        //         'customer',
                        //         selectedOption.value
                        //     );
                        //     // const payload = {
                        //     //     _id: selectedOption.value,
                        //     //     populate: true,
                        //     // };
                        //     // const string = QueryString.stringify(payload);
                        //     // const resp = await authAxiosInstance.get(
                        //     //     `/customer?${string}`
                        //     // );
                        //     // console.log('resp', resp?.data?.data?.docs[0]);
                        //     // const inks = resp?.data?.data?.docs[0]?.ink;
                        //     // setInkOptions(inks);
                        // }}
                        required
                    />
                )}

                {/* {formik?.values?.company && <FormikAsyncSelect
                    formik={formik}
                    name={'product'}
                    label="Product"
                    getOptions={async (value) => {
                        try {
                            const string = QueryString.stringify({
                                search: value,
                                type: { $ne: 'MACHINE' },
                            });
                            const response = await authAxiosInstance.get(
                                `products?${string}`
                            );

                            const options = response?.data?.data?.docs?.map(
                                (ele) => ({
                                    label: ele.name,
                                    value: ele?._id,
                                })
                            );
                            return options;
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    required
                />} */}
                {formik?.values?.company && (
                    <FormikSelectGroup
                        formik={formik}
                        name={'product'}
                        label="Product"
                        options={generateOptions({
                            array: inkOptions?.map((ele) => ele.item) || [],
                            valueField: '_id',
                            labelField: 'name',
                        })}
                        onChange={(selectedOptions) => {
                            formik.setFieldValue(
                                'product',
                                selectedOptions.value
                            );

                            inkOptions?.forEach((ele) => {
                                if (ele?.item?._id === selectedOptions.value) {
                                    setMOQ({
                                        cyan: ele.cyan,
                                        magenta: ele.magenta,
                                        yellow: ele.yellow,
                                        black: ele.black,
                                    });
                                }
                            });
                        }}
                        required
                    />
                )}

                {/* <FormikAsyncSelect
                    formik={formik}
                    name={'customer'}
                    label="Customer"
                    getOptions={async (value) => {
                        try {
                            const string = QueryString.stringify({
                                search: value,
                            });
                            const response = await authAxiosInstance.get(
                                `customer?${string}`
                            );

                            const options = response?.data?.data?.docs?.map(
                                (ele) => ({
                                    label: ele.name,
                                    value: ele?._id,
                                })
                            );
                            return options;
                        } catch (error) {
                            console.log(error);
                        }
                    }}
                    required
                /> */}
                {formik?.values?.company && (
                    <FormikSelectGroup
                        formik={formik}
                        name={'status'}
                        label="Status"
                        options={PURCHASE_ORDER_STATUS}
                    />
                )}
                {formik?.values?.company && (
                    <FormikTextareaGroup
                        name="notes"
                        formik={formik}
                        label="Notes"
                    />
                )}
                {formik?.values?.company && (
                    <FormikInputGroup
                        formik={formik}
                        name={'cyan'}
                        type="number"
                        label={
                            <div className="flex flex-row items-center gap-2">
                                <p>Cyan</p>
                                {MOQ.cyan > 0 && (
                                    <p>{`( min order qty : ${MOQ.cyan} )`}</p>
                                )}
                            </div>
                        }
                    />
                )}
                {formik?.values?.company && (
                    <FormikInputGroup
                        formik={formik}
                        name={'magenta'}
                        type="number"
                        label={
                            <div className="flex flex-row items-center gap-2">
                                <p>Magenta</p>
                                {MOQ.magenta > 0 && (
                                    <p>{`( min order qty : ${MOQ.magenta} )`}</p>
                                )}
                            </div>
                        }
                    />
                )}
                {formik?.values?.company && (
                    <FormikInputGroup
                        formik={formik}
                        name={'yellow'}
                        type="number"
                        label={
                            <div className="flex flex-row items-center gap-2">
                                <p>Yellow</p>
                                {MOQ.yellow > 0 && (
                                    <p>{`( min order qty : ${MOQ.yellow} )`}</p>
                                )}
                            </div>
                        }
                    />
                )}
                {formik?.values?.company && (
                    <FormikInputGroup
                        formik={formik}
                        name={'black'}
                        type="number"
                        label={
                            <div className="flex flex-row items-center gap-2">
                                <p>Black</p>
                                {MOQ.black > 0 && (
                                    <p>{`( min order qty : ${MOQ.black} )`}</p>
                                )}
                            </div>
                        }
                    />
                )}
                {formik?.values?.company && (
                    <FormikInputGroup
                        formik={formik}
                        name={'cleaningSolution'}
                        label="Cleaning Solution"
                    />
                )}

                <div className="mb-2">
                    {formik.isSubmitting ? (
                        <ClipLoader />
                    ) : (
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    )}
                </div>
            </form>
        </PageWithCard>
    );
};

export default CreatePurchaseOrder;
