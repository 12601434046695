import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProductContainer from '../Users/ProductContainer';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import {
    deleteCustomers,
    editCustomers,
    fetchCustomers,
    getCustomers,
} from '../../app/reducers/Customers/customerSlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';

const CustomerList = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const { user } = useSelector(getAuth);
    const { customer, loading } = useSelector(getCustomers);
    console.log('customer', customer);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCustomers({ populate: true, approvedStatus: 'pending' }));
    }, []);

    const columns = [
        {
            Header: 'Sr.no',
            Cell: ({ row }) => {
                console.log('row', row?.original);
                return <div>{row?.index + 1}</div>;
            },
        },
        {
            Header: 'Customer Name',
            accessor: 'name',
        },
        {
            Header: 'Company Name',
            accessor: 'company.0.name',
        },
        {
            Header: 'Executive',
            Cell: ({ row }) => {
                return (
                    <ul>
                        {row?.original?.executive?.map((exe) => (
                            <li>
                                {exe.firstName} {exe.lastName}
                            </li>
                        ))}
                    </ul>
                );
            },
        },
        {
            Header: 'Machine',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.machine);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Machine
                    </div>
                );
            },
        },
        {
            Header: 'INK',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.ink);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Ink
                    </div>
                );
            },
        },
        // {
        //     Header: 'Previous Assign Executive',
        //     Cell: ({ row }) => {
        //         return row?.original?.previousExecutiveUsers?.map((el, i) => (
        //             <div key={el._id}>
        //                 {`${el.firstName} ${el.lastName}`}
        //                 {i !== row?.original?.previousExecutiveUsers?.length - 1
        //                     ? ','
        //                     : ''}
        //             </div>
        //         ));
        //     },
        // },
        // {
        //     Header: 'Current Assign Executive',
        //     Cell: ({ row }) => {
        //         return row?.original?.executiveUsers?.map((el, i) => (
        //             <div key={el._id}>
        //                 {`${el.firstName} ${el.lastName}`}
        //                 {i !== row?.original?.executiveUsers?.length - 1
        //                     ? ','
        //                     : ''}
        //             </div>
        //         ));
        //     },
        // },
        {
            Header: 'Action',

            Cell: ({ row }) => {
                return (
                    <div className="flex flex-row items-center gap-4">
                        {['sup_admin', 'admin'].includes(user?.role) && (
                            <>
                                <PrimaryButton
                                    type="button"
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const resp = await dispatch(
                                            editCustomers({
                                                _id: row?.original?._id,
                                                approvedStatus: 'approved',
                                            })
                                        );
                                        if (resp?.payload?.success) {
                                            const res =
                                                await authAxiosInstance.post(
                                                    '/customer/sendWhatsAppMessage',
                                                    row?.original
                                                );
                                            if (res?.data?.success) {
                                                toast.success(
                                                    `${res?.data?.message}`
                                                );
                                            }
                                        }
                                        await dispatch(
                                            fetchCustomers({
                                                populate: true,
                                                approvedStatus: 'pending',
                                            })
                                        );
                                    }}
                                >
                                    {loading ? <ClipLoader /> : 'Approve'}
                                </PrimaryButton>
                                <DangerButton
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        const confirm = window.confirm('Are you sure you want to reject this customer ?\n' +
                                            'Once reject it will not refetch again ..!'
                                        )
                                        if(confirm){                                           
                                            await dispatch(
                                                deleteCustomers({
                                                    id: row?.original?._id,
                                                })
                                            );
                                            await dispatch(
                                                fetchCustomers({
                                                    populate: true,
                                                    approvedStatus: 'pending',
                                                })
                                            );
                                        }

                                    }}
                                >
                                    Reject
                                </DangerButton>
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    const colsMemo = useMemo(() => columns ?? [], [columns]);
    const dataMemo = useMemo(
        () => (customer?.docs ? customer.docs : []),
        [customer]
    );
    return (
        <PageWithCard>
            <ProductContainer
                modalData={modalData}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
            <TableWithHeadingAndSearch
                data={dataMemo}
                columns={colsMemo}
                heading={'Pending Customers List'}
            />
        </PageWithCard>
    );
};

export default CustomerList;
