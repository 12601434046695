import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { useDispatch } from 'react-redux';
import {
    editComplaint,
    fetchComplaints,
} from '../../app/reducers/Complaints/complaintSlice';

const CancelModal = ({
    openCancelModal,
    setOpenCancelModal,
    complaintData,
    page,
    complaintStatus,
    activeButtonIndex,
}) => {
    console.log('compalint data', complaintData)
    const dispatch = useDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...complaintData,
            cancellationNotes: '',
            cancelDate: '',
        },
        validationSchema: object({
            cancellationNotes: string().required(),
            cancelDate: string().required(),
        }),
        onSubmit: async (values) => {
            console.log('values', values);
            await dispatch(
                editComplaint({
                    ...values,
                    status: 'canceled',
                })
            );
            await dispatch(
                fetchComplaints({
                    populate: true,
                    page,
                    status: `${complaintStatus[activeButtonIndex]}`,
                })
            );
            setOpenCancelModal(false);
            formik.resetForm();
        },
    });
    return (
        <ModalBasic
            modalOpen={openCancelModal}
            setModalOpen={setOpenCancelModal}
            title={'Cancel Complaint'}
        >
            <div className="p-4">
                <form
                    className="flex flex-col gap-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputDateGroup
                        formik={formik}
                        label="Cancel Date"
                        name="cancelDate"
                        required
                    />
                    <FormikInputGroup
                        formik={formik}
                        name="cancellationNotes"
                        label="Cancellation Note"
                    />
                    <div className="">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            </div>
        </ModalBasic>
    );
};

export default CancelModal;
