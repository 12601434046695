import React, { useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import Header from '../../iml_header.jpg';
import Footer from '../../iml_footer.jpg';
import { ClipLoader } from 'react-spinners';

const WhatsAppNumbersModal = ({
    openNumbersModal,
    setOpenNumbersModal,
    rowData,
    user,
}) => {
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            receiverNumbers: [user?.username],
        },
        onSubmit: async (values) => {
            console.log('values', values);
            try {
                setLoading(true);
                const response = await authAxiosInstance.post(
                    `/quotation/pdfViaMessage`,
                    {
                        ...rowData,
                        receiverNumbers: values?.receiverNumbers,
                        header: `https://s3.ap-south-1.amazonaws.com/media.imlgroup.softwave.in/quotations/HeaderFooter/iml_header.jpg`,
                        footer: `https://s3.ap-south-1.amazonaws.com/media.imlgroup.softwave.in/quotations/HeaderFooter/iml_footer.jpg`,
                    }
                );
                // console.log('resp', response)
                if (response?.data?.success) {
                    toast.success(`${response?.data?.message}`);
                }
                setOpenNumbersModal(false);
                formik.resetForm();
                setLoading(false);
            } catch (error) {
                console.log('error', error);
                toast.error('Failed to send pdf');
            } finally {
                setLoading(false);
            }
        },
    });
    return (
        <ModalBasic
            modalOpen={openNumbersModal}
            setModalOpen={setOpenNumbersModal}
            title={'Add WhatsApp Numbers'}
        >
            <div className="p-4">
                {loading ? (
                    <ClipLoader />
                ) : (
                    <form
                        className="flex flex-col gap-4"
                        onSubmit={formik.handleSubmit}
                    >
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="receiverNumbers"
                                render={(arrayHelpers) => {
                                    return (
                                        <div className="flex flex-col gap-2 border p-2 rounded-md">
                                            <label className="text text-base font-semibold ">
                                                Numbers
                                            </label>
                                            <div>
                                                {formik.values.receiverNumbers.map(
                                                    (ele, index) => (
                                                        <div
                                                            className="relative p-4 mb-2"
                                                            style={{
                                                                border: '1px solid #d6c7c7',
                                                                borderRadius:
                                                                    '5px',
                                                            }}
                                                            key={index}
                                                        >
                                                            <div className="flex flex-col gap-2">
                                                                <div className="w-full">
                                                                    <FormikPhoneInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        label={`Phone`}
                                                                        name={`receiverNumbers.${index}`}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="mt-6 cursor-pointer">
                                                                <DangerButton
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </DangerButton>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        arrayHelpers.push(
                                                            '+91'
                                                        );
                                                    }}
                                                    type="button"
                                                >
                                                    Add More
                                                </SecondaryButton>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </FormikProvider>
                        <div>
                            <PrimaryButton type="submit">Send</PrimaryButton>
                        </div>
                    </form>
                )}
            </div>
        </ModalBasic>
    );
};

export default WhatsAppNumbersModal;
