import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';

const MachineContainer = ({ modalData, setIsModalOpen, isModalOpen }) => {
    return (
        <ModalBasic modalOpen={isModalOpen} setModalOpen={setIsModalOpen}>
            <div className="grid grid-cols-3 gap-3 p-2">
                {modalData?.length > 0
                    ? modalData?.map((el, i) => (
                          <div
                              key={el?._id}
                              className="flex flex-col gap-3 p-2 justify-center items-center"
                          >
                              <div className="flex flex-row gap-1 ">
                                  <p>{i + 1}.</p>
                                  <div style={{ position: 'relative' }}>
                                      <img
                                          src={`${process.env.REACT_APP_S3_URL}${el?.photo}`}
                                          style={{
                                              width: '200px',
                                              height: '200px',
                                          }}
                                      />
                                  </div>
                              </div>
                              <p className="text text-base font-semibold text-left">
                                  {el?.name}
                              </p>
                          </div>
                      ))
                    : 'No Data Found'}
            </div>
        </ModalBasic>
    );
};

export default MachineContainer;
