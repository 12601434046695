import { useCallback, useEffect, useMemo, useState } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
    createProduct,
    deleteProduct,
    fetchProducts,
    getProducts,
} from '../../app/reducers/Products/productSlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ShowDropdown from '../../components/infrastructure/ShowDropdown';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import PaginationClassic from '../../components/infrastructure/pagination/PaginationClassic';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { PRODUCT_TYPE } from '../../utils/dropdownOptions';
import { Download, Edit2, Trash2 } from 'react-feather';
import EditProduct from './EditProduct';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import DocumentsModal from './DocumentsModal';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import moment from 'moment';
import { exportCsv } from '../../utils/Utils';
import { ClipLoader } from 'react-spinners';

const AddProduct = () => {
    const dispatch = useDispatch();
    const [docsData, setDocsData] = useState({});
    const [openDocumentsModal, setOpenDocumentsModal] = useState(false);

    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [productEditId, setProductEditId] = useState(null);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const { products, loading } = useSelector(getProducts);

    useEffect(() => {
        dispatch(fetchProducts({ populate: true, page, sort: { type: 1 } }));
    }, [page]);

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            dispatch(
                fetchProducts({
                    populate: true,
                    page,
                    search,
                })
            );
        }, 300),
        [products?.docs]
    );

    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            photo: '',
            hsnCode:'',
            documents: [],
        },
        onSubmit: async (values) => {
            await dispatch(createProduct(values));
            await dispatch(
                fetchProducts({ populate: true, page, sort: { type: 1 } })
            );
        },
    });

    const cols = [
        {
            Header: 'Photo',
            Cell: ({ row }) => {
                return (
                    <div>
                        {row?.original?.photo ? (
                            <img
                                src={`${process.env.REACT_APP_S3_URL}${row.original.photo}`}
                                style={{ maxWidth: '170px' }}
                            />
                        ) : (
                            <p>no image</p>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2">
                        <Edit2
                            color="blue"
                            className="cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowEditProductModal(true);
                                setProductEditId(row.original._id);
                            }}
                        />
                        <Trash2
                            color="red"
                            className="cursor-pointer"
                            onClick={async () => {
                                await dispatch(
                                    deleteProduct({ id: row.original._id })
                                );
                                dispatch(
                                    fetchProducts({
                                        populate: true,
                                        page,
                                        sort: { type: 1 },
                                    })
                                );
                            }}
                        />
                    </div>
                );
            },
        },
        {
            Header: 'View',
            Cell: ({ row }) => {
                return (
                    row?.original?.type === 'MACHINE' && (
                        <div
                            className="cursor-pointer text-blue-400 hover:text-blue-600"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenDocumentsModal(true);
                                setDocsData(row?.original?.documents);
                            }}
                        >
                            Documents
                        </div>
                    )
                );
            },
        },
    ];

    const data = useMemo(
        () => (products?.docs ? products?.docs : []),
        [products]
    );
    return (
        <PageWithCard>
            <EditProduct
                productEditId={productEditId}
                setProductEditId={setProductEditId}
                page={page}
            />
            <DocumentsModal
                openDocumentsModal={openDocumentsModal}
                setOpenDocumentsModal={setOpenDocumentsModal}
                data={docsData}
            />
            <div>
                
                    <ShowDropdown
                        heading={'Add Products'}
                        allowedRoles={['admin', 'sup_admin']}
                    >
                        <form
                            onSubmit={formik.handleSubmit}
                            className="flex flex-col gap-4 mb-4"
                        >
                            <FormikDirectImageUpload
                                label="Photo"
                                name={'photo'}
                                location={'iml_group/products'}
                                randomize
                                formik={formik}
                            />
                            <FormikInputGroup
                                formik={formik}
                                name={'name'}
                                label="Name"
                                required
                            />
                            <FormikSelectGroup
                                formik={formik}
                                label="Type"
                                name={'type'}
                                options={PRODUCT_TYPE}
                                required
                            />
                            <FormikInputGroup 
                                formik={formik}
                                label='HSN Code'
                                name='hsnCode'
                            />
                            {formik?.values?.type === 'MACHINE' && (
                                <FormikProvider value={formik}>
                                    <FieldArray
                                        name="documents"
                                        render={(arrayHelpers) => {
                                            return (
                                                <div className="flex flex-col gap-2 border p-2 rounded-md">
                                                    <label className="text text-base font-semibold ">
                                                        Documents
                                                    </label>
                                                    <div>
                                                        {formik.values.documents.map(
                                                            (ele, index) => (
                                                                <div
                                                                    className="relative p-4 mb-2"
                                                                    style={{
                                                                        border: '1px solid #d6c7c7',
                                                                        borderRadius:
                                                                            '5px',
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <div className="flex flex-col gap-2">
                                                                        <div className="w-full">
                                                                            <FormikInputGroup
                                                                                formik={
                                                                                    formik
                                                                                }
                                                                                name={`documents.${index}.name`}
                                                                                label="Name"
                                                                            />
                                                                        </div>
                                                                        <div className="w-full">
                                                                            <FormikDirectFileUpload
                                                                                formik={
                                                                                    formik
                                                                                }
                                                                                name={`documents.${index}.file`}
                                                                                label="File"
                                                                                randomize={
                                                                                    true
                                                                                }
                                                                                location={
                                                                                    'iml_group/products/docs'
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-6 cursor-pointer">
                                                                        <DangerButton
                                                                            type="button"
                                                                            onClick={() =>
                                                                                arrayHelpers.remove(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove
                                                                        </DangerButton>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                    <div>
                                                        <SecondaryButton
                                                            onClick={() => {
                                                                arrayHelpers.push(
                                                                    {}
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            Add More
                                                        </SecondaryButton>
                                                    </div>
                                                </div>
                                            );
                                        }}
                                    />
                                </FormikProvider>
                            )}
                            <div>
                                <PrimaryButton
                                    type="submit"
                                    disabled={formik.isSubmitting}
                                >
                                    Submit
                                </PrimaryButton>
                            </div>
                        </form>
                    </ShowDropdown>
                    
                <TableWithHeadingAndGlobalSearch
                    loading={loading}
                    data={data}
                    columns={cols}
                    heading={<div className='flex flex-row items-center gap-4'>
                        <h3>Products</h3>
                        {isLoading ? (
                        <ClipLoader size={16} />
                    ) : (
                        <SecondaryButton
                            className="py-1"
                            onClick={async (e) => {
                                e.stopPropagation();
                                try {
                                    setIsLoading(true);
                                    const queryStr = QueryString.stringify({
                                        populate: true,
                                        limit: 5000,
                                    });
                                    const resp = await authAxiosInstance.get(
                                        `/products?${queryStr}`
                                    );
                                    if (resp?.data?.data?.docs?.length > 0) {
                                        let mapData =
                                            resp?.data?.data?.docs?.map(
                                                (ele, index) => {
                                                   
                                                    const {
                                                        name,
                                                        type,
                                                        createdAt,
                                                    } = ele;
                                                    return {
                                                        Sr_No :`${index + 1}`, 
                                                        Product_Name: name,
                                                        Product_Type: type,
                                                        Created_At:
                                                            moment(
                                                                createdAt
                                                            ).format(
                                                                'DD/MM/YYY HH:mm'
                                                            ),
                                                    };
                                                }
                                            );
                                        exportCsv(mapData);
                                    }
                                    setIsLoading(false);
                                } catch (error) {
                                    console.log('Error', error);
                                } finally {
                                    setIsLoading(false);
                                }
                            }}
                        >
                            <Download size={16} className="mr-2" />
                            Product Csv
                        </SecondaryButton>
                    )}
                    </div>}
                    searchFunction={debouncedSearch}
                />
                <PaginationClassic
                    paginationDetails={products}
                    setPage={setPage}
                />
            </div>
        </PageWithCard>
    );
};

export default AddProduct;
