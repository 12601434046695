import React, { useMemo } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { Download } from 'react-feather';

const DocumentsModal = ({
    openDocumentsModal,
    setOpenDocumentsModal,
    data,
}) => {
    const docsMemo = useMemo(()=>{
        if(data && data?.length > 0){
            return data
        } else {
            return []
        }
    },[data])
    return (
        <ModalBasic
            modalOpen={openDocumentsModal}
            setModalOpen={setOpenDocumentsModal}
            title={'Documents'}
        >
            <div className="p-4">
               { docsMemo.length > 0 ? (<table className="table-auto w-full border border-black">
                    <thead>
                        <tr>
                            <th className="px-2 py-3 border">Sr No.</th>
                            <th className="px-2 py-3 border">Documents Name</th>
                            <th className="px-2 py-3 border">Download</th>
                        </tr>
                    </thead>
                    <tbody>
                        {docsMemo?.map((doc, index) => (
                            <tr className="p-2 border" key={doc._id}>
                                <td className='text-center px-2 py-3'>
                                    { index + 1}
                                </td>
                                <td className="text-left px-2 py-3 border">
                                    {doc.name}
                                </td>
                                <td className="text-center px-2 py-3 border">
                                    <button>
                                        <a
                                            href={`${process.env.REACT_APP_S3_URL}${doc.file}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                        >
                                            <Download size={18} color="blue" />
                                        </a>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>) : 'No Documents Uploaded !!'}
            </div>
        </ModalBasic>
    );
};

export default DocumentsModal;
