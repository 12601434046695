import React, { useEffect, useState } from 'react';
import EditModal from '../../components/infrastructure/Modals/EditModal';
import { FieldArray, Formik, FormikProvider, useFormik } from 'formik';
import { fetchEditData } from '../../app/generators/generateThunks';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    editProduct,
    fetchProduct,
    fetchProducts,
    getProducts,
    resetEditData,
} from '../../app/reducers/Products/productSlice';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { PRODUCT_TYPE } from '../../utils/dropdownOptions';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import FormikDirectImageUpload from '../../components/formik/FormikDirectImageUpload';
import FormikDirectFileUpload from '../../components/formik/FormikDirectFileUpload';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';

const EditProduct = ({ productEditId, setProductEditId, page }) => {
    const [showModal, setShowModal] = useState(false);
    const { elementEditData, editDataLoading, loading } =
        useSelector(getProducts);

    const dispatch = useDispatch();

    useEffect(() => {
        if (productEditId) {
            dispatch(fetchProduct({ _id: productEditId }));
            setShowModal(true);
        } else {
            setProductEditId(null);
            dispatch(resetEditData());

            setShowModal(false);
        }
    }, [productEditId]);

    const computeInitialValue = (data)=>{
        if(data){
            return{
                ...data,
                _id : data?._id ?? '',
                name : data?.name ?? '',
                photo : data?.photo ?? '',
                type : data?.type ?? '',
                documents : data?.documents ?? [],
                hsnCode : data?.hsnCode ?? '',
            }
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(elementEditData),
        onSubmit: async (values) => {
            console.log(values);
            await dispatch(editProduct(values));
            await dispatch(
                fetchProducts({ populate: true, page, sort: { type: 1 } })
            );
            setShowModal(false);
        },
    });
    return (
        <ModalBasic
            modalOpen={showModal}
            setModalOpen={setShowModal}
            onModalClose={() => {
                console.log('modal close');
                dispatch(resetEditData());
                setProductEditId(null);
            }}
        >
            {editDataLoading || loading ? (
                <ClipLoader />
            ) : (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikDirectImageUpload
                        name={'photo'}
                        label="photo"
                        location={'iml_group/products'}
                        formik={formik}
                    />
                    <FormikInputGroup
                        name={'_id'}
                        formik={formik}
                        label="System Id"
                        readOnly
                        required
                    />
                    <FormikInputGroup
                        name={'name'}
                        formik={formik}
                        label="Name"
                        required
                    />
                    <FormikSelectGroup
                        name={'type'}
                        formik={formik}
                        label="Type"
                        options={PRODUCT_TYPE}
                        required
                    />
                    <FormikInputGroup
                        formik={formik}
                        label="HSN Code"
                        name="hsnCode"
                    />
                    {formik?.values?.type === 'MACHINE' && (
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="documents"
                                render={(arrayHelpers) => {
                                    return (
                                        <div className="flex flex-col gap-2 border p-2 rounded-md">
                                            <label className="text text-base font-semibold ">
                                                Documents
                                            </label>
                                            <div>
                                                {formik.values.documents.map(
                                                    (ele, index) => (
                                                        <div
                                                            className="relative p-4 mb-2"
                                                            style={{
                                                                border: '1px solid #d6c7c7',
                                                                borderRadius:
                                                                    '5px',
                                                            }}
                                                            key={index}
                                                        >
                                                            <div className="flex flex-col gap-2">
                                                                <div className="w-full">
                                                                    <FormikInputGroup
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`documents.${index}.name`}
                                                                        label="Name"
                                                                    />
                                                                </div>
                                                                <div className="w-full">
                                                                    <FormikDirectFileUpload
                                                                        formik={
                                                                            formik
                                                                        }
                                                                        name={`documents.${index}.file`}
                                                                        label="File"
                                                                        randomize={
                                                                            true
                                                                        }
                                                                        location={
                                                                            'iml_group/products/docs'
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="mt-6 cursor-pointer">
                                                                <DangerButton
                                                                    type="button"
                                                                    onClick={() =>
                                                                        arrayHelpers.remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </DangerButton>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            <div>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        arrayHelpers.push({});
                                                    }}
                                                    type="button"
                                                >
                                                    Add More
                                                </SecondaryButton>
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                        </FormikProvider>
                    )}
                    <div>
                        <PrimaryButton
                            type="submit"
                            disabled={formik.isSubmitting}
                        >
                            Edit
                        </PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EditProduct;
