import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProductContainer from '../Users/ProductContainer';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import { ClipLoader } from 'react-spinners';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import {
    deleteCompany,
    editCompany,
    editStatusCompany,
    fetchCompanies,
    getCompanies,
} from '../../app/reducers/Company/companySlice';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import { fetchCustomers } from '../../app/reducers/Customers/customerSlice';

const CompanyList = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const { user } = useSelector(getAuth);
    const { company, loading } = useSelector(getCompanies);
    console.log('company', company);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCompanies({ populate: true, approvedStatus: 'pending' }));
    }, []);

    const columns = [
        {
            Header: 'Sr.no',
            Cell: ({ row }) => {
                return <div>{row?.index + 1}</div>;
            },
        },
        {
            Header: 'Company Name',
            accessor: 'name',
        },
        {
            Header : 'Executive',
            Cell : ({row})=>{
                return(
                    <ul>{ row?.original?.executive?.map((exe)=>(
                        <li>{exe.firstName} {exe.lastName}</li>
                    )) }</ul>
                )
            }
        },
        {
            Header: 'Machine',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.machine);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Machine
                    </div>
                );
            },
        },
        {
            Header: 'INK',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                            setModalData(row?.original?.ink);
                        }}
                        className="text text-blue-700 underline hover:cursor-pointer"
                    >
                        View Ink
                    </div>
                );
            },
        },
        {
            Header: 'Action',

            Cell: ({ row }) => {
                return (
                    <div className='flex flex-row items-center gap-4'>
                        {['sup_admin', 'admin'].includes(user?.role) && (
                            <>
                            <PrimaryButton
                                type="button"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const resp = await dispatch(
                                        editStatusCompany({
                                            _id: row?.original?._id,
                                            approvedStatus: 'approved',
                                        })
                                    );
                                    if (resp?.payload?.success) {
                                        const res =
                                            await authAxiosInstance.post(
                                                '/company/sendMessages',
                                                row?.original
                                            );
                                        if(res?.data?.success){
                                            toast.success(`${res?.data?.message}`)
                                        }
                                    }
                                    await dispatch(
                                        fetchCompanies({
                                            populate: true,
                                            approvedStatus: 'pending',
                                        })
                                    );
                                }}
                            >
                                {loading ? <ClipLoader /> : 'Approve'}
                            </PrimaryButton>
                            <DangerButton
                                onClick={async(e)=>{
                                    e.stopPropagation();
                                    const anyCustomer = await dispatch(
                                        fetchCustomers({company : row?.original?._id})
                                    )
                                    if(anyCustomer?.payload?.data?.docs?.length > 0){
                                        return toast.error('You cannot reject/delete this company')
                                    } else {
                                        await dispatch(
                                            deleteCompany({ id:  row?.original?._id})
                                        )
                                        await dispatch(
                                            fetchCompanies({
                                                populate: true,
                                                approvedStatus: 'pending',
                                            })
                                        );
                                    }
                                }}
                            >
                                Reject
                            </DangerButton>
                            </>
                        )}
                    </div>
                );
            },
        },
    ];

    const colsMemo = useMemo(() => columns ?? [], [columns]);
    const dataMemo = useMemo(
        () => (company?.docs ? company.docs : []),
        [company]
    );
    return (
        <PageWithCard>
            <ProductContainer
                modalData={modalData}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
            />
            <TableWithHeadingAndSearch
                data={dataMemo}
                columns={colsMemo}
                heading={'Pending Company List'}
            />
        </PageWithCard>
    );
};

export default CompanyList;
