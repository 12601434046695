import { useState } from 'react';
import { useRef } from 'react';
import { bytesToMegaBytes } from '../../utils/Utils';
import { useDispatch } from 'react-redux';
import {
    createObject,
    deleteSingleObject,
} from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { openConfirmPopUp } from '../../app/reducers/ConfirmPopUp/confirmPopUpSlice';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { X } from 'react-feather';
import customId from 'custom-id';
import { useEffect } from 'react';
import FormikInputGroup from './FormikInputGroup';

const FormikDirectFileUpload = ({
    name,
    formik,
    label = '',
    required,
    location,
    randomize = false,
    height,
    width,
    ...props
}) => {
    const ref = useRef();
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('file');
    const [finalString, setFinalString] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        if (_.at(formik.values, name)?.[0]) {
            setFinalString(_.at(formik.values, name)[0]);
        } else{
            setFinalString('')
        }
    }, [_.at(formik.values, name)?.[0]]);

    return (
        <div>
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            {finalString ? (
                <div className="flex">
                    <p className="truncate">{finalString}</p>
                    <X
                        color="red"
                        onClick={async () => {
                            dispatch(
                                openConfirmPopUp({
                                    isOpen: true,
                                    message:
                                        'Are you sure you want to execute this operation?',
                                    buttons: [
                                        {
                                            name: 'Remove',
                                            onClick: async (e) => {
                                                e.stopPropagation();
                                                setFinalString('');
                                                formik.setFieldValue(name, '');
                                                dispatch(
                                                    openConfirmPopUp({
                                                        isOpen: false,
                                                        message: '',
                                                        buttons: [],
                                                    })
                                                );
                                                ref.current.value = '';
                                            },
                                        },
                                        {
                                            name: 'Delete from system',
                                            onClick: async (e) => {
                                                e.stopPropagation();

                                                await dispatch(
                                                    deleteSingleObject({
                                                        keys: [finalString],
                                                    })
                                                );
                                                setFinalString('');
                                                ref.current.value = '';
                                                formik.setFieldValue(name, '');
                                                dispatch(
                                                    openConfirmPopUp({
                                                        isOpen: false,
                                                        message: '',
                                                        buttons: [],
                                                    })
                                                );
                                            },
                                        },
                                        {
                                            name: 'Cancel',
                                            onClick: (e) => {
                                                e.stopPropagation();

                                                dispatch(
                                                    openConfirmPopUp({
                                                        isOpen: false,
                                                        message: '',
                                                        buttons: [],
                                                    })
                                                );
                                            },
                                        },
                                    ],
                                    data: {},
                                })
                            );
                        }}
                    />
                </div>
            ) : (
                <>
                    {mode == 'file' && (
                        <>
                            {loading ? (
                                <ClipLoader />
                            ) : (
                                <input
                                    ref={ref}
                                    type="file"
                                    // onLoad={(event) => {
                                    //   console.log("eventwidth", event.width);
                                    // }}
                                    onChange={async (e) => {
                                        // console.log("photo on event", e);
                                        console.log(
                                            bytesToMegaBytes(
                                                e.currentTarget.files[0].size
                                            )
                                        );
                                        if (
                                            bytesToMegaBytes(
                                                e.currentTarget.files[0].size
                                            ) < 30
                                        ) {
                                            setLoading(true);
                                            try {
                                                const resp = await dispatch(
                                                    createObject({
                                                        location: location,
                                                        file: e.currentTarget
                                                            .files[0],
                                                        fileName: randomize
                                                            ? `${e.currentTarget.files[0].name}${customId({})}`
                                                            : e.currentTarget
                                                                  .files[0]
                                                                  .name,
                                                    })
                                                );
                                                console.log(resp);
                                                if (
                                                    resp?.payload?.data?.[0]
                                                        ?.Location
                                                ) {
                                                    // const myImage = new Image();
                                                    // myImage.src = resp?.payload?.data?.[0]?.Location;
                                                    // console.log(myImage, "myImage");
                                                    // console.log(myImage.width, "myImageW");
                                                    // console.log(myImage.height, "myImageH");
                                                    // if (height & width) {
                                                    //   // formik.setFieldValue(height, "")
                                                    // }
                                                    setFinalString(
                                                        resp.payload.data[0].Key
                                                    );
                                                    formik.setFieldValue(
                                                        name,
                                                        resp.payload.data[0].Key
                                                    );
                                                } else {
                                                    throw new Error(
                                                        'Payload error'
                                                    );
                                                }
                                            } catch (error) {
                                                if (error.message) {
                                                    toast.error(error.message);
                                                } else {
                                                    toast.error(
                                                        'error uploading file'
                                                    );
                                                }
                                            } finally {
                                                setLoading(false);
                                            }
                                        } else {
                                            formik.setFieldValue(name, null);
                                            ref.current.value = '';
                                            alert('file size too large');
                                        }
                                    }}
                                    {...props}
                                    className="w-full form-input"
                                    onBlur={formik.handleBlur}
                                />
                            )}
                        </>
                    )}
                </>
            )}

            {mode == 'link' && (
                <>
                    <input
                        ref={ref}
                        name={name}
                        className="w-full form-input"
                        type="text"
                        value={_.at(formik.values, name)?.[0]}
                        onChange={(e) => {
                            setFinalString(e.target.value);
                            formik.setFieldValue(name, e.target.value);
                        }}
                        placeholder="Enter Link"
                    />
                </>
            )}
            <div
                className="text-cyan-700 underline cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation();
                    setFinalString('');
                    ref.current.value = '';
                    formik.setFieldValue(name, '');
                    setMode(mode == 'file' ? 'link' : 'file');
                }}
            >
                mode
            </div>
            {formik.errors[name] && formik.touched[name] ? (
                <p className="text-xs text-red-500">{formik.errors[name]}</p>
            ) : null}
        </div>
    );
};

export default FormikDirectFileUpload;
