import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Copy, Edit, MessageSquare, Printer } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import {
    editQuotation,
    editQuotationStatus,
    fetchQuotations,
    getQuotation,
} from '../../app/reducers/Quotation/quotationSlice';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import ViewProductsModal from './ViewProductsModal';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import { toast } from 'react-toastify';
import WhatsAppNumbersModal from './WhatsAppNumbersModal';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';

const ViewQuotation = () => {
    const { user } = useSelector(getAuth);
    const [isProductModalOpen, setIsProductModalOpen] = useState(false);
    const [quotationProductsData, setQuotationProductsData] = useState([]);
    const [openNumbersModal, setOpenNumbersModal] = useState(false);
    const [rowData, setRowData] = useState({});

    const { quotation, loading } = useSelector(getQuotation);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (['sup_admin', 'admin'].includes(user.role)) {
            dispatch(
                fetchQuotations({
                    populate:true,
                    approvalStatus: 'approved',
                    // status: 'pending',
                })
            );
        } else {
            dispatch(
                fetchQuotations({
                    populate:true,
                    approvalStatus: 'approved',
                    createdBy: user?.username,
                    // status: 'pending',
                })
            );
        }
    }, []);

    const columns = [
        {
            Header: 'SrNo',
            Cell: ({ row }) => {
                return row.index + quotation?.pagingCounter;
            },
        },
        {
            Header: 'Quotation No',
            accessor: 'ref_no',
        },
        {
            Header: 'Customer Name',
            accessor: 'customerName',
        },
        {
            Header: 'Contact No',
            accessor: 'contactNumber',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
        {
            Header: 'Products',
            Cell: ({ row }) => {
                return (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsProductModalOpen(true);
                            setQuotationProductsData(row?.original?.products);
                        }}
                        className="text-blue-400 underline hover:text-blue-700 cursor-pointer"
                    >
                        View Products
                    </div>
                );
            },
        },
        {
            Header: 'Total Products Price',
            Cell: ({ row }) => {
                const totalProductPrice = row?.original?.products?.reduce(
                    (acc, crr) => {
                        acc += parseFloat(crr.totalPrice || 0);
                        return acc;
                    },
                    0
                );

                return totalProductPrice.toFixed(2);
            },
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: ' ',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col items-center gap-2">
                        {['sup_admin', 'admin'].includes(user?.role) && (
                            <>
                                {['pending','rejected'].includes(row?.original?.status) && <PrimaryButton
                                    className="py-1"
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        await dispatch(
                                            editQuotationStatus({
                                                _id: row?.original?._id,
                                                status: 'approved',
                                            })
                                        );
                                        if (
                                            ['sup_admin', 'admin'].includes(
                                                user.role
                                            )
                                        ) {
                                            dispatch(
                                                fetchQuotations({
                                                    populate:true,
                                                    approvalStatus: 'approved',
                                                    // status: 'pending',
                                                })
                                            );
                                        } else {
                                            dispatch(
                                                fetchQuotations({
                                                    populate:true,
                                                    approvalStatus: 'approved',
                                                    createdBy: user?.username,
                                                    // status: 'pending',
                                                })
                                            );
                                        }
                                    }}
                                >
                                    Approve
                                </PrimaryButton>}
                                {['pending','approved'].includes(row?.original?.status) &&<DangerButton
                                    className="py-1"
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        await dispatch(
                                            editQuotationStatus({
                                                _id: row?.original?._id,
                                                status: 'rejected',
                                            })
                                        );
                                        if (
                                            ['sup_admin', 'admin'].includes(
                                                user.role
                                            )
                                        ) {
                                            dispatch(
                                                fetchQuotations({
                                                    populate:true,
                                                    approvalStatus: 'approved',
                                                    // status: 'pending',
                                                })
                                            );
                                        } else {
                                            dispatch(
                                                fetchQuotations({
                                                    populate:true,
                                                    approvalStatus: 'approved',
                                                    createdBy: user?.username,
                                                    // status: 'pending',
                                                })
                                            );
                                        }
                                    }}
                                >
                                    Reject
                                </DangerButton>}
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row }) => {
                return ['pending','rejected'].includes(row?.original?.status) ? (
                    <div className="flex flex-row items-center gap-2">
                        {['sup_admin', 'admin'].includes(user?.role) && (
                            <Copy
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(
                                        `/quotation/duplicate-quotation/${row.original._id}`
                                    );
                                }}
                            />
                        )}
                        {
                            <Edit
                                className='cursor-pointer'
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(
                                        `/quotation/edit-quotation/${row.original._id}`
                                    );
                                }}
                                color="blue"
                            />
                        }
                        {['sup_admin', 'admin'].includes(user?.role) && (
                            <Printer
                                className='cursor-pointer'
                                color="blue"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const response =
                                        await authAxiosInstance.post(
                                            `/quotation/quotationPdf`,
                                            {
                                                ...row?.original,
                                                header: `https://s3.ap-south-1.amazonaws.com/media.imlgroup.softwave.in/quotations/HeaderFooter/iml_header.jpg`,
                                                footer: `https://s3.ap-south-1.amazonaws.com/media.imlgroup.softwave.in/quotations/HeaderFooter/iml_footer.jpg`,
                                            }
                                        );
                                    const printerWindow = window.open(
                                        '',
                                        '_blank'
                                    );
                                    printerWindow.document.write(response.data);
                                }}
                            />
                        )}
                        {['sup_admin', 'admin'].includes(user?.role) && (
                            <MessageSquare
                                className='cursor-pointer'
                                color="green"
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setRowData(row?.original);
                                    setOpenNumbersModal(true);
                                    // const response = await authAxiosInstance.post(
                                    //     `/quotation/pdfViaMessage`, row?.original
                                    // )
                                    // console.log('resp', response)
                                    // if(response?.data?.success){
                                    //     toast.success(`${response?.data?.message}`)
                                    // }
                                }}
                            />
                        )}
                    </div>
                ) : (
                    <div className="flex flex-row items-center gap-2">
                        <Copy
                            className='cursor-pointer'
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/quotation/duplicate-quotation/${row.original._id}`
                                );
                            }}
                        />
                        <Edit
                            className='cursor-pointer'
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(
                                    `/quotation/edit-quotation/${row.original._id}`
                                );
                            }}
                            color="blue"
                        />
                        <Printer
                            className='cursor-pointer'
                            color="blue"
                            onClick={async (e) => {
                                e.stopPropagation();
                                const response = await authAxiosInstance.post(
                                    `/quotation/quotationPdf`,
                                    {
                                        ...row?.original,
                                        header: `https://s3.ap-south-1.amazonaws.com/media.imlgroup.softwave.in/quotations/HeaderFooter/iml_header.jpg`,
                                        footer: `https://s3.ap-south-1.amazonaws.com/media.imlgroup.softwave.in/quotations/HeaderFooter/iml_footer.jpg`,
                                    }
                                );
                                const printerWindow = window.open('', '_blank');
                                printerWindow.document.write(response.data);
                            }}
                        />
                        <MessageSquare
                            className='cursor-pointer'
                            color="green"
                            onClick={async (e) => {
                                e.stopPropagation();
                                setRowData(row?.original);
                                setOpenNumbersModal(true);
                                // const response = await authAxiosInstance.post(
                                //     `/quotation/pdfViaMessage`, row?.original
                                // )
                                // console.log('resp', response)
                                // if(response?.data?.success){
                                //     toast.success(`${response?.data?.message}`)
                                // }
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const colsMemo = useMemo(() => columns ?? [], [columns]);
    const dataMemo = useMemo(
        () => (quotation?.docs ? quotation.docs : []),
        [quotation]
    );
    return (
        <PageWithCard heading="View Quotation">
            <ViewProductsModal
                openModal={isProductModalOpen}
                setOpenModal={setIsProductModalOpen}
                products={quotationProductsData}
            />
            <WhatsAppNumbersModal
                openNumbersModal={openNumbersModal}
                setOpenNumbersModal={setOpenNumbersModal}
                rowData={rowData}
                user={user}
            />
            <TableWithHeadingAndSearch
                columns={colsMemo}
                data={dataMemo}
                heading={loading ? <ClipLoader /> : 'View Quotation'}
            />
        </PageWithCard>
    );
};

export default ViewQuotation;
