import React from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { editComplaint, fetchComplaints } from '../../app/reducers/Complaints/complaintSlice';

const ObservationModal = ({
    openObservationModal,
    setOpenObservationModal,
    complaintData,
    page,
    complaintStatus,
    activeButtonIndex,
}) => {
    console.log('compalint data', complaintData)
    const dispatch = useDispatch();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...complaintData,
            observationNotes: '',
            observationDate:'',
        },
        validationSchema: object({
            observationNotes: string().required(),
            observationDate: string().required(),
        }),
        onSubmit:async(values)=>{
            console.log('values', values)
            // const newObsDate = moment(values?.observationDate).valueOf();
            await dispatch(editComplaint({
                ...values,
                // observationDate : newObsDate,
                status : 'observation'
            }))
            await dispatch(fetchComplaints({
                populate : true,
                page,
                status : `${complaintStatus[activeButtonIndex   ]}`
            }))
            setOpenObservationModal(false);
            formik.resetForm();
        }
    });
    return (
        <ModalBasic
            modalOpen={openObservationModal}
            setModalOpen={setOpenObservationModal}
            title={'Add Observation'}
        >
            <div className="p-4">
                <form
                    className="flex flex-col gap-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputDateGroup
                        formik={formik}
                        label="Observation Date"
                        name="observationDate"
                        required
                    />
                    <FormikInputGroup
                        formik={formik}
                        name="observationNotes"
                        label="Observation Note"
                    />
                    <div className="">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            </div>
        </ModalBasic>
    );
};

export default ObservationModal;
